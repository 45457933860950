<template>
  <ws-dialog
      v-model="display"
      :title="$t('mailer.send.test.title')"
      :save-text="$t('Send')"
      :disable-save="!email || sendingTimeout"
      :loading="LOADING"
      @save="sendTestEmail"
  >
    <ws-text-field
      v-model="email"
      label="Email"
      placeholder="test@example.ua"
      :error="emailError"
      @input="emailError = false"
    />
    <!-- CONTENT-->

    <ws-timer v-if="sendingTimeout" @finish="sendingTimeout = false" class="mt-2" #default="{time,finished}" seconds="300">
      <h5 class="wsDARKLIGHT">
        {{ $t('ResendAgain') }} <span v-if="!finished">: {{ time }} </span>
      </h5>
    </ws-timer>

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "emailPreviewDialog",
  props : {
    value : {
      type : Boolean
    },
    uuid : {
      type : String
    }
  },
  data() {
    return {
      display : false,
      email : '',
      sendingTimeout : false,
      emailError : false
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('finemailer' , ['SEND_TEST_EMAIL']),


    async sendTestEmail( ) {
      if (!this.VALIDATE_EMAIL(this.email)) {
        this.emailError = true
        return this.notify(this.$t('WrongEmail') , 'warning')
      }
      const data = {
        newsletter_id : this.uuid,
        email : this.email
      }

      let result = await this.SEND_TEST_EMAIL(data)
      if ( !result ) {
        if (this.$store.state.ajax.error === 'Test letters can be send only once per 5 minutes') {
          this.sendingTimeout = true
          this.notify(this.$t('SendTestTimeout') , 'warning')
          return
        }
        return this.ERROR()
      }
      this.notify(this.$t('mailer.send.test.completed') , 'success')
      this.display = false
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>
