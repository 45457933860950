<template>
  <v-sheet height="60" v-if="loading" :color="wsLIGHTCARD" class="d-flex justify-center align-center" >
    <v-progress-linear indeterminate class="mx-16" height="24" rounded :color="wsBACKGROUND" />
  </v-sheet>
  <v-hover v-else v-model="hover">
    <div @click.stop="selectBlock"  style="position:relative" :style="selectOutlineStyle">

      <!-- Add Block Top Button -->
      <email-edit-side-menu
          custom-style="position: absolute; right: -80px; z-index: 1;"
          v-if="isSelected"
          :items="editItemsSelect"
          :is-first="block.order_number === 1"
          :is-last="isLast"
          @action="blockAction"
      />

      <!-- Block design-->
      <v-sheet
          v-if="display && block.elements.length"
          class="d-flex "  :style="blockStyle"
                 :class="[{'flex-row' : !EMAIL_MOBILE_VIEW}, {'flex-column' : EMAIL_MOBILE_VIEW}]"
      >

        <email-column-editor
            v-for="column in block.config.columns" :key="column"
            @add="handleAddButton($event.type, $event.position , column)"
            @duplicate-element="handleDuplicateElement($event)"
            @edit-element="handleEditElement"
            @move-element="handleMoveElement"
            @delete-element="handleDeleteElement"
            :elements="getColumnElements(column)"
            :column="column"
            :block="block"
            :config="EMAIL_CONFIG"

        />
      </v-sheet>
      <!-- Empty Block-->
      <v-hover v-else-if="!block.elements.length" v-model="hover">
        <v-sheet
            :color="wsLIGHTCARD"
            :style="emptyStyle + `;border : 1px solid ${wsBACKGROUND};` + selectOutlineStyle"
            class="pa-6 mb-6"  style="position:relative"
        >


          <v-row no-gutters>
            <v-col v-for="(item,i) in EMAIL_ELEMENTS_SELECT" :key="i + 'element'" :cols="EMAIL_MOBILE_VIEW ? 6 : 4">
              <v-hover #default="{hover}">
                <v-sheet
                    @click.stop="addElement(item.value , true , 1)"
                    :style="`border : 1px solid ${wsBACKGROUND} !important; outline : ${hover ? 3 : 0}px solid ${wsBACKGROUND}`"
                    v-ripple
                    class="pa-2 wsRoundedLight pointer ma-1">
                  <div class="d-flex justify-center">
                    <v-icon class="mx-auto" :color="wsATTENTION">{{ item.icon }}</v-icon>
                  </div>

                  <h5 class="text-center font-weight-regular wsDARKER mt-1" >{{ item.text }}</h5>
                </v-sheet>
              </v-hover>
            </v-col>
          </v-row>

        </v-sheet>
      </v-hover>


      <editorAddBlockButton v-if="false" />

    </div>
  </v-hover>
</template>

<script>
import emailColumnEditor
  from "@/modules/finemailer/components/emailConstructor/editor/emailColumnEditor";
import  {mapState,mapActions} from "vuex";
import finemailer from "@/modules/finemailer/mixins/finemailer";
import emailEditSideMenu
  from "@/modules/finemailer/components/emailConstructor/editor/UI/emailEditSideMenu";

import editorAddBlockButton from "./UI/editorAddBlockButton.vue";
export default {
  name: "emailBlockEditor",
  mixins : [finemailer],
  components : {
    emailColumnEditor,
    emailEditSideMenu,
    editorAddBlockButton
  },
  props : {
    uuid : {
      type : String,
    },
    index : {
      type : Number,
    },
    isLast : {
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      loading : false,
      hover : false,
      dropdownActive : false,
      block : {
        config : {},
        elements : []
      },
      display : false,
      isSaved : false,
    }
  },
  computed : {
    ...mapState('finemailer' , [
        'blockEditTrigger',
        'selectedBlock',
        'elementHover',
        'navigation',
        'newBlockId',
        'newBlockPosition',
        'blockTemplateSelectTrigger'
    ]),

    editItemsSelect() {
      let items = this.COPY(this.EMAIL_BLOCK_EDIT_ACTIONS_SELECT)
      if ( this.index === 0) {
        items[0].disabled = true
        items = this.COPY(items)
      }
      if (this.block.elements.length > 0) {
        items.splice(-1, 0,{
          icon : `mdi-bookmark${this.isSaved ? '' : '-outline' }` , value : 'save_template'
        })
      }
      if ( this.isLast ) {
        items[1].disabled = true
        items = this.COPY(items)
      }
      return items
    },
    DEFAULT_BLOCK_CONFIG() {
      return {
        "columns" : 1,
        "padding_top" : 24,
        "padding_bottom" : 24,
        "padding_left" : 24,
        "padding_right" : 24,
      }
    },
    EMAIL_ELEMENTS() {
      return {
        text : {
          type : 'text',
          content : null,
          config : {
            font_style : "p",
            align : 'left'
          }
        },
        heading : {
          type : 'text',
          content : null,
          config : {
            font_style : "h2",
            align : 'left'
          }
        },
        image : {
          type : 'image',
          content : null,
          config : {
            width : 100,
            align : 'left'
          }
        },
        button : {
          type : 'button',
          content : 'Кнопка',
          config : {
            style : "primary",
            align : 'center'
          }
        },
        link : {
          type : 'button',
          content : 'Кнопка',
          config : {
            style : "text",
            align : 'left',
          }
        },
        line : {
          type : 'line',
          content : '',
          config : {
            width : 100,
            color : null,
            align : 'left'
          }
        },
        file : {
          type : 'file',
          content : null,
          config : {
            align : 'left'
          }
        }
      }
    },
    isSelected() {
      return this.selectedBlock.uuid === this.block.uuid && this.navigation === 'block'
    },
    hoverActive() {
      if ( this.isSelected ) {
        return true
      }
      return (this.hover || this.dropdownActive) && !this.$store.state.finemailer.elementHover
    },
    addBlockItems() {
      if (![1,3].includes(this.block.config.columns ) ) {
        return []
      }
      let items = this.COPY(this.EMAIL_ELEMENTS_SELECT)
      items.unshift( { text : this.$t('AddBlock') ,
        value : 'block' ,
        icon : 'mdi-widgets',
      })
      return items
    },
    selectOutlineStyle() {
      let style = ''

      if ( this.hoverActive) {
        style += `outline : 2px solid ${this.wsATTENTION};outline-offset: -2px !important;`
      }

      return style
    },

    emptyStyle() {
      let style = ''

      if ( this.block.config.margin_top ) {
        style += `margin-top : ${this.block.config.margin_top}px !important; `
      }

      if ( this.block.config.margin_bottom ) {
        style += `margin-bottom : ${this.block.config.margin_bottom}px !important; `
      }

      return style
    },

    blockStyle() {
      let style = ''

      if ( this.block.config.padding_top) {
        style += `padding-top : ${this.block.config.padding_top}px;`
      }
      if ( this.block.config.padding_bottom) {
        style += `padding-bottom : ${this.block.config.padding_bottom}px;`
      }
      if ( this.block.config.padding_left) {
        style += `padding-left : ${this.block.config.padding_left}px;`
      }
      if ( this.block.config.padding_right) {
        style += `padding-right : ${this.block.config.padding_right}px;`
      }

      if ( this.block.config.background_color ) {
        style += `background-color : ${this.EMAIL_COLOR(this.block.config.background_color)} !important; `
      } else {
        style += `background-color : transparent !important; `
      }

      if ( this.block.config.border ) {
        style += `border : ${this.block.config.border}px solid  ${this.EMAIL_COLOR(this.block.config.border_color)} !important; `
      }

      if ( this.block.config.border_radius ) {
        style += `border-radius : ${this.block.config.border_radius}px !important; `
      }

      if ( this.block.config.margin_top ) {
        style += `margin-top : ${this.block.config.margin_top}px !important; `
      }

      if ( this.block.config.margin_bottom ) {
        style += `margin-bottom : ${this.block.config.margin_bottom}px !important; `
      }



      return style
    }
  },
  watch : {
    blockTemplateSelectTrigger() {

      if (this.selectedBlock.uuid !== this.block.uuid) {
        return
      }
      this.initPage(true)
    },
    index() {
      this.block.order_number = this.index + 1
      this.block = this.COPY(this.block)
    },
    blockEditTrigger() {
      if ( this.selectedBlock.uuid !== this.block.uuid ) {
        return
      }
      this.editBlockConfig()
    },
  },
  methods : {
    ...mapActions('finemailer' , [
        'GET_NEWSLETTER_DESIGN_BLOCK',
        'ADD_EDIT_DESIGN_BLOCK',
        'ADD_EDIT_DESIGN_ELEMENT',
        'SAVE_BLOCK_AS_TEMPLATE'
    ]),

    // Block Functions

    async editBlockConfig() {
      this.block.config = this.COPY(this.selectedBlock.config)
      this.block = this.COPY(this.block)
      this.$store.state.finemailer.selectedBlock = this.COPY(this.block)
      let result = await this.ADD_EDIT_DESIGN_BLOCK(this.block)
      if ( !result ) {
        return this.ERROR()
      }

    },

    addBlock(type , bottom) {

      const element = this.COPY(this.EMAIL_ELEMENTS[type])

      const block = {
        type : 'column',
        config : this.DEFAULT_BLOCK_CONFIG,
        elements : [element]
      }

      let data = {
        block : block,
        bottom : bottom
      }
      this.$emit('add' , data)
    },

    duplicateBlock() {
      let block = this.COPY(this.block)
      block.template_id = block.uuid
      let data = {
        block : block,
        bottom : true
      }
      this.$emit('add' , data)
    },

    blockAction(action) {
      switch(action) {
        case 'duplicate' : return this.duplicateBlock()
        case 'move_up' : return this.$emit('move' , -1)
        case 'move_down' : return this.$emit('move' , 1)
        case 'delete' : return this.$emit('delete')
        case 'save_template' : return this.saveAsTemplate()
      }
    },

    async saveAsTemplate() {
      if (this.isSaved || this.LOADING) {
        return
      }
      if (!await this.SAVE_BLOCK_AS_TEMPLATE({ template_id: this.block.uuid })) {
        return this.ERROR()
      }
      this.isSaved = true
      this.notify(this.$t('mailer.templates.blocks.saved') , 'success')

    },

    selectBlock() {
      this.$store.state.finemailer.selectedElement = {config : {}}
      this.$store.state.finemailer.selectedBlock = this.block
      this.$store.state.finemailer.navigation = 'block'
    },

    // Element functions

    async addElement(type , position , column = 1) {

      const element =  this.COPY(this.EMAIL_ELEMENTS[type])
      if ( this.block.elements.length > 0) {
        element.config.margin_top = 8 ;
      }
      element.block_id = this.uuid
      element.column = column
      element.order_number = position
      let result = await this.ADD_EDIT_DESIGN_ELEMENT(element)
      if ( !result ) {
        return
      }

      this.block.elements.forEach((element,index) => {
        if ( element.column === column && element.order_number >= position) {
          this.block.elements[index].order_number = element.order_number + 1
        }
      })

      this.block.elements.push(result)
      this.block = this.COPY(this.block)

    },

    async handleDuplicateElement({element , position}) {
      const column = element.column
      let data = {
        template_id : element.uuid,
        column : column,
        order_number : position
      }
      let result = await this.ADD_EDIT_DESIGN_ELEMENT(data)
      if ( !result ) {
        return
      }

      this.block.elements.forEach((element,index) => {
        if ( element.column === column && element.order_number >= position) {
          this.block.elements[index].order_number = element.order_number + 1
        }
      })

      this.block.elements.push(result)
      this.block = this.COPY(this.block)

    },

    handleEditElement(element) {
      let index = this.block.elements.findIndex(el => el.uuid === element.uuid)
      if (index === -1) {
        return
      }
      this.block.elements[index] = element
      this.selectedElement = this.COPY(element)
      this.block = this.COPY(this.block)
    },

    handleMoveElement({uuid , direction}) {
      let index = this.block.elements.findIndex(el => el.uuid === uuid )
      if ( index === -1 ) {
        return
      }
      let element = this.block.elements[index]
      let targetElementIndex = this.block.elements.findIndex(el => el.order_number === (element.order_number + direction) && el.column === element.column )
      if (targetElementIndex === -1) {
        return
      }
      this.block.elements[targetElementIndex].order_number = element.order_number
      this.block.elements[index].order_number += direction
      this.block = this.COPY(this.block)
    },

    handleDeleteElement(elementUuid) {
      let index = this.block.elements.findIndex(el => el.uuid === elementUuid )
      if ( index === -1 ) {
        return
      }
      let {order_number , column} = this.block.elements[index]
      this.block.elements.forEach((item , i) => {
        if (item.order_number > order_number && item.column === column) {
          this.block.elements[i].order_number -= 1
        }
      })
      this.block.elements.splice(index , 1)
      this.block = this.COPY(this.block)
    },

    // Technical

    handleAddButton(type , position , column) {
      if ( type === 'block' || !type ) {
        this.addBlock(type , +1)
        return
      }

      this.addElement(type , position , column)

    },

    getColumnElements(column) {
      return this.block.elements.filter(el => el.column === column).sort((a, b) => a.order_number - b.order_number);
    },

    async initPage(updateSelectedBlock = false) {
      this.loading = true
      let result = await this.GET_NEWSLETTER_DESIGN_BLOCK(this.uuid)
      this.loading = false

      if (!result) {
        return
      }

      this.block = result
      this.display = true
      if ( this.$store.state.finemailer.newBlockId === this.block.uuid ) {
        this.selectBlock()
      }
      if (updateSelectedBlock) {
        this.$store.state.finemailer.selectedBlock = this.COPY(this.block)
      }
    }
  },
  beforeMount() {
    this.EMAIL_CONFIG = this.config
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>