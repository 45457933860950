<template>
  <ws-dialog
      v-model="display"
      :title="$t('Preview')"
      @save="$emit('save')"
      height="100%"
      width="95%"
      max-width="1046px"
      not-centered
      hide-buttons
  >


    <template #default>
      <slot>
        <email-viewer
            :entity="entity"
            :uuid="uuid"
            :super-admin="superAdmin"
            :disable-mobile-view="disableMobile"
        />
      </slot>
    </template>

    <template #footer>
      <slot name="footer"></slot>
    </template>

    <!-- CONTENT-->

  </ws-dialog>
</template>

<script>
import EmailViewer from "@/modules/finemailer/components/emailConstructor/viewer/EmailViewer.vue";
export default {
  name: "emailPreviewDialog",
  components : {
    EmailViewer
  },
  props : {
    value : {
      type : Boolean
    },
    entity : {
      type : Object,
      default () { return {} }
    },
    uuid : {
      type : String
    },
    superAdmin : {
      type : Boolean,
      default : false
    },
    disableMobile : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>
