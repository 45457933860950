<template>
  <v-hover v-model="hover">
    <div  style="position:relative;"
          :style="columnStyle"
          @click="selectColumn"

    >

      <v-sheet class="d-flex flex-column fill-height" color="transparent"  width="100%" min-width="100%" >

        <div v-if="topSpacerCondition" class="flex-grow-1" > </div>

        <template v-for="(element,index) in elements" >

          <email-element-editor
              @add="$emit('add',$event)"
              @duplicate="$emit('duplicate-element',$event)"
              @edit="$emit('edit-element', $event)"
              @move="$emit('move-element', $event)"
              @delete="$emit('delete-element' , $event)"
              :key="element.uuid"
              :element="element"
              :index="index"
              :config="EMAIL_CONFIG"
          />

        </template>

        <v-sheet
            v-if="elements.length === 0" :color="wsLIGHTCARD + '66'" width="100%"
            class="pa-6 d-flex align-center justify-center"
            style="position:relative;"
            min-height="100"
        >
          <ft-select
              style="position: absolute"
              :items="EMAIL_ELEMENTS_SELECT"
              @input="$emit('add',{type : $event , position : 1 })"
          >
              <ws-button
                  v-if="hover"
                  label="Add"
                  :click-stop="false"
              />
          </ft-select>
          <h5 v-if="!hover" class="text-center font-weight-regular" :style="`color : ${wsDARKLIGHT}`">Додайте елементи</h5>
        </v-sheet>

        <div v-if="bottomSpacerCondition" class="flex-grow-1" />

      </v-sheet>

    </div>
  </v-hover>

</template>

<script>
import emailElementEditor
  from "@/modules/finemailer/components/emailConstructor/editor/emailElementEditor";

import finemailer from "@/modules/finemailer/mixins/finemailer";

export default {
  name: "emailBlockEditor",
  mixins : [finemailer],
  components : {
    emailElementEditor,
  },
  props : {
    elements : {
      type : Array,
      default() {
        return []
      }
    },
    block : {
      type : Object,
      default() {
        return { config : {} }
      }
    },
    column : {
      type : Number
    }
  },
  data() {
    return {
      dropdownActive : false,
      hover : false
    }
  },
  computed : {
    isSelected() {
      return this.$store.state.finemailer.selectedColumnIndex === this.column
          && this.$store.state.finemailer.selectedBlock.uuid === this.block.uuid
          && this.$store.state.finemailer.navigation === 'column'
    },
    topSpacerCondition() {
      const align = this.block.config[`col_${this.column}_vertical_align`];

      if (['bottom' , 'middle'].includes(align)) {
        return true
      }

      return false
    },
    bottomSpacerCondition() {
      const align = this.block.config[`col_${this.column}_vertical_align`];

      if (['top' , 'middle'].includes(align)) {
        return true
      }

      return false
    },
    blockColumns() {
      return this.block.config.columns
    },
    columnStyle() {
      let style = ''
      let width = 100

      if ( !this.EMAIL_MOBILE_VIEW && this.blockColumns === 2) {
        width = this.column === 1 ? this.block.config.proportion_half : (100 - this.block.config.proportion_half)
      }

      style += `width : ${width}%;`

      if ( this.block.config.column_padding ) {
        if ( this.column === 1 ||( this.column === 2 && this.blockColumns === 3)) {
          style += `margin-right : ${this.block.config.column_padding}px;`
        }
      }

      if ( this.blockColumns > 1 ) {

        if ( this.block.config[`col_${this.column}_background_color`]) {
          style += `background-color : ${this.EMAIL_COLOR(this.block.config[`col_${this.column}_background_color`])};`
        }

        if ( this.block.config[`col_${this.column}_border`]) {
          style += `border : ${this.block.config[`col_${this.column}_border`]}px solid ${this.EMAIL_COLOR(this.block.config[`col_${this.column}_border_color`])};`
        }

        if ( this.block.config[`col_${this.column}_border`]) {
          style += `border : ${this.block.config[`col_${this.column}_border`]}px solid ${this.EMAIL_COLOR(this.block.config[`col_${this.column}_border_color`])};`
        }

        if ( this.block.config[`col_${this.column}_border_radius`]) {
          style += `border-radius : ${this.EMAIL_COLOR(this.block.config[`col_${this.column}_border_radius`])}px;`
        }


        if ( this.block.config[`col_${this.column}_padding_top`]) {
          style += `padding-top : ${this.EMAIL_COLOR(this.block.config[`col_${this.column}_padding_top`])}px;`
        }
        if ( this.block.config[`col_${this.column}_padding_bottom`]) {
          style += `padding-bottom : ${this.EMAIL_COLOR(this.block.config[`col_${this.column}_padding_bottom`])}px;`
        }
        if ( this.block.config[`col_${this.column}_padding_left`]) {
          style += `padding-left : ${this.EMAIL_COLOR(this.block.config[`col_${this.column}_padding_left`])}px;`
        }
        if ( this.block.config[`col_${this.column}_padding_right`]) {
          style += `padding-right : ${this.EMAIL_COLOR(this.block.config[`col_${this.column}_padding_right`])}px;`
        }

        if ( this.isSelected ) {
          style += `outline : 1px solid ${this.wsATTENTION}`
        }

      }


      return style

    },
    displayAddButton() {
      if ( this.blockColumns === 1 || (this.blockColumns === 3 && this.column === 2) || this.elements.length === 0 ) {
        return false
      }
      return this.hover || this.dropdownActive
    },
  },
  methods : {
    selectColumn() {
      this.$store.state.finemailer.selectedColumnIndex = this.column
    }
  }
}
</script>

<style scoped>

</style>