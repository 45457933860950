import { render, staticRenderFns } from "./NewsletterWizard.vue?vue&type=template&id=aca531a0&scoped=true&"
import script from "./NewsletterWizard.vue?vue&type=script&lang=js&"
export * from "./NewsletterWizard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aca531a0",
  null
  
)

export default component.exports