<template>
  <ws-dialog
      v-model="display"
      :title="$t('mailer.wizard.choose_type')"
      no-navigation
  >

    <v-hover #default="{hover}" v-for="type in ['single' , 'auto']" :key="type">
      <v-sheet
          @click="$emit('select' , type)"
          :style="`border : 1px solid ${hover ? wsACCENT : wsBACKGROUND}`"
          class="px-4 py-8 d-flex align-center wsRoundedSemi pointer mb-4"

      >
        <v-img
            :src="`/library/img/finemailer/newsletter_${type}.png`"
            max-height="100" max-width="100"
            height="100" width="100"
            contain
            style="margin-right: 30px"
        />
        <div>
          <h4 class="wsDARKER">{{ $t(`mailer.wizard.${type}.title`) }}</h4>
          <h5 class="wsACCENT font-weight-regular mt-2">{{ $t(`mailer.wizard.${type}.description`) }}</h5>
        </div>
      </v-sheet>
    </v-hover>



  </ws-dialog>
</template>

<script>
export default {
  name: "NewsletterWizard",
  props : {
    value : {
      type : Boolean
    },
  },
  data() {
    return {
      display : false,
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>